<template>

  <div class="pros" v-if="products?.items?.length">

    <h2 class="divider-sec">
      {{ props.title }} 
      <nuxt-link class="h2_show_all" :to="link">{{ props.showmore }}</nuxt-link>
    </h2>

    <div class="proslist" v-if="products.items">
      <template v-for="pro in products.items">
        <BrandProductItem
          :nm="pro.name"
          :nmalt="pro.name"
          :avatar="pro.cover_s"
          :link="pro.link"
          :rate="pro.item_stat.avg_rate"
          :cnt_harvests="pro.item_stat.cnt_harvests"
          :cnt_diaries="pro.item_stat.cnt_diaries"
          />
      </template>
    </div>
  </div>

</template>

<script setup lang="ts">

import { getTagsKeyword, getTagsKeys, getTagsSimple  } from '@/types/other'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  showmore: {
    type: String,
    required: true
  },
  brandId: {
    type: Number,
    required: true
  },
  link: {
    type: String,
    required: true
  },
  products: {
    type: Array,
    required: true
  },
  category: {
    type: String,
    required: false,
    default: ''
  },
  intags: {
    type: Object,
    required: false
  }, 
  rateof: {
    type: Number,
    required: false,
    default: 10
  }
})
  
const {$api} = useNuxtApp()

const isEnded = ref(false)
const start = ref(0);
const limit = ref(10);
const sort = ref('az_asc');
const tagsselected = ref([]);
const tagspermanent = ref([]);

if(props.brandId){
  tagspermanent.value.push('br:' + props.brandId);
}
tagspermanent.value.push('not_tests');


const loadData = async function() {     
  const response = await $api.getProducts({
    category: props.category,
    start: start.value,
    limit: limit.value,
    q: getTagsKeyword(props.intags, tagsselected.value, tagspermanent.value),
    sortable: sort.value,
    tagsall: getTagsKeys(props.intags),
    tags: getTagsSimple(props.intags, tagsselected.value),
    tagsper: getTagsSimple(props.intags, tagspermanent.value)
  });
  return response;  
}

const { pending: is_loading,  data: products } = await useLazyAsyncData('products', async () => await loadData())
 
</script>

<style scoped>
 
 
 

.proslist {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.proslist.centered {
  justify-content: center;
}


</style>
